import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Analytics } from '@vercel/analytics/react';
import axios from 'axios';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './App.css';


import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

function App() {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [fetchAttempted, setFetchAttempted] = useState(false);

  useEffect(() => {
    const fetchLocationData = () => {
      fetch('/api/location')
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          setCountry(decodeURIComponent(data.country));
          setCity(decodeURIComponent(data.city));
          
          const cleanCity = data.city.replace(/[0-9]/g, '');
          const cleanCountry = data.country.replace(/[0-9]/g, '');
  
          const address = `${encodeURIComponent(cleanCity)}, ${encodeURIComponent(cleanCountry)}`;
          const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyBTT1Epe-qKRGcLSPos2waBZ-2M5dkMfew`;
          return axios.get(url);
        })
        .then(response => {
          const results = response.data.results;
          if (results.length > 0) {
            const addressComponents = results[0].address_components;
            const stateObj = addressComponents.find(component => component.types.includes('administrative_area_level_1'));
            if (stateObj) {
              setRegion(stateObj.long_name);
            }
          }
          setFetchAttempted(true);
        })
        .catch(error => {
          console.error('Fetch error:', error);
          setCountry('Unavailable');
          setRegion('');
          setCity('');
          setFetchAttempted(true);
        });
    };
  
    if (!region && !fetchAttempted) {
      fetchLocationData();
    }
  }, [region]); 



  const imageUrls = [
    'https://i.ibb.co/D4qzj9s/2024-03-13-00-48-32.jpg',
    'https://i.ibb.co/cQgC3wW/2024-03-13-00-48-36.jpg',
    'https://i.ibb.co/4t3f69B/2024-03-13-00-48-38.jpg',
    'https://i.ibb.co/c3Hx7Cx/2024-03-13-00-48-40.jpg',
    'https://i.ibb.co/QJJch77/2024-03-13-00-48-42.jpg',
    'https://i.ibb.co/yg7y0FF/2024-03-13-00-48-44.jpg',
    'https://i.ibb.co/W2VVdCP/2024-03-13-00-48-47.jpg',
    'https://i.ibb.co/mt1xT0K/2024-03-13-00-48-48.jpg',
    'https://i.ibb.co/K25qLkh/2024-03-13-00-48-51.jpg'
  ];

  return (
    <div className="container">
      <img 
        src="https://assets.cdn.filesafe.space/CJkDD96k2jOfuywnYhgP/media/65a51f792aaf1b036608af13.png" 
        alt="Header Image"
        className="header-image"
      />
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={'3'}
        coverflowEffect={{
        rotate: 15,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
       }}
      autoplay={{ 
        delay: 2000,
        disableOnInteraction: false,
      }}
      modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
      className="swiper-container"
    >
      {imageUrls.map(url => (
        <SwiperSlide key={url} className="swiper-slide">
          <img src={url} alt="slide_image"/>
        </SwiperSlide>
      ))}
        <div className="slider-controler">
          <div className="swiper-pagination"></div>
        </div>
      </Swiper>

          <div className='textContainer' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
        <p style={{ fontSize: '31px', margin: '0 0 10px 0' }}> 
            <strong>🎁80% OFF SALE🎁 Lillian 🍭</strong>
        </p>
        <div className="detailsText">
            <strong>📍 {city}, {country} {country && <img 
                src={`https://flagcdn.com/w20/${country.toLowerCase()}.png`} 
                alt={`${country} Flag`}
                style={{ width: '20px', height: '15px', verticalAlign: 'middle' }}
            />} ❤️18 years ♉ Taurus<br/></strong>
            🟢 Online Now<br/><br/>

            Get exclusive access to my content and interact with me directly. <br/><br/>

            🍑 💦 Do you live near<strong> {city}</strong>?📍 DM me about making content 😉 🍑 🔥
        </div>
    </div>



      <a href="https://onlyfans.com/jellybellylillian/c74" id="customButton" >
        Send me a message
        <img src="https://www.edigitalagency.com.au/wp-content/uploads/OnlyFans-logo-symbol-icon-png-blue-background.png" alt="Logo" />
      </a>

      <p className="exclusiveContent">
       <strong>Get access to my private account now!<br/>
       Only $3.00/m for a limited time!</strong>
      </p>
      <Analytics/>
    </div>
  );
}

export default App;
